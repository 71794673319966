import {isRouteErrorResponse, useRouteError} from '@remix-run/react';
import React, {useEffect} from 'react';

import {NotFound} from '~/components';
import {ShowError} from '~/foundation/AppSetupContext';
import {logUnknownError} from '~/utils/convertUnknownToError';

export function AppErrorBoundary() {
  const routeError = useRouteError();
  const isRouteError = isRouteErrorResponse(routeError);
  useEffect(() => {
    logUnknownError(routeError, {unknown404: false});
  }, [routeError]);

  if (isRouteError && routeError.status === 404) {
    return <NotFound />;
  }

  return <ShowError />;
}
